<template>
  <el-main>
    <router-link to="/extension/PCOfficialWebsite/addNewsInformation"><el-button style="margin-bottom: 10px" size="small" type="primary" icon="el-icon-plus">添加资讯</el-button></router-link>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="资讯标题：">
        <el-input size="small" placeholder="请输入资讯标题" v-model="title"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getList()">搜索</el-button>
        <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="article-list">
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="id" label="ID" align="center"></el-table-column>
        <el-table-column prop="picture" label="资讯主图" align="center">
          <template slot-scope="scope">
            <el-image :src="scope.row.picture" style="width: 50px; height: 50px"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="资讯标题" align="center"></el-table-column>
        <el-table-column label="置顶" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_top" :active-value="1" :inactive-value="0" @change="tableChange(scope.row, 'is_top')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="推荐" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_recommend" :active-value="1" :inactive-value="0" @change="tableChange(scope.row, 'is_recommend')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" align="center">
          <template slot-scope="scope">
            <span>{{ getDateformat(scope.row.update_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="remove(scope.row.id, scope.$index)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      page: 1,
      pageNum: 10,
      total: 0,
      title: '',
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    cancelSearch() {
      this.title = '';
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    getList() {
      this.$axios
        .post(this.$api.PCOfficialWebsite.artList, {
          page: this.page,
          rows: this.pageNum,
          title: this.title,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    edit(row) {
      this.$router.push({
        path: '/extension/PCOfficialWebsite/editNewsInformation?id=' + row.id,
      });
    },
    remove(id, index) {
      this.$confirm('确认删除此资讯？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .post(this.$api.PCOfficialWebsite.delArt, {
              id: id,
            })
            .then(res => {
              if (res.code == 0) {
                this.$message({
                  message: '删除成功',
                  type: 'success',
                });
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(err => {});
    },
    tableChange(row, type) {
      this.$axios
        .post(this.$api.PCOfficialWebsite.sortArt, {
          id: row.id,
          is_top: row.is_top,
          is_recommend: row.is_recommend,
        })
        .then(res => {
          if (res.code == 0) {
            this.getList();
            this.$message({
              message: '编辑成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
            row[type] = !row[type];
          }
        });
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>

<style lang="less" scoped>
.el-table {
  border-left: 1px solid #e8eef1;
  border-right: 1px solid #e8eef1;
  /deep/ .el-input__inner {
    border-color: #fff;
  }
  /deep/ .el-input__inner:focus {
    border-color: #409eff;
  }
}
.el-main {
  background: #fff;
}
</style>
